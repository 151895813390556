import { FullHeader } from './FullHeader';
import { SlimHeader } from './SlimHeader';
import { FullFooter } from './FullFooter';
import { SlimFooter } from './SlimFooter';

export const componentMap = {
  'full-header': FullHeader,
  'slim-header': SlimHeader,
  'full-footer': FullFooter,
  'slim-footer': SlimFooter,
};

export const defaultComponent = SlimFooter;

export const getComponent = (component?: string | null) => {
  if (!component) {
    return defaultComponent;
  }
  return componentMap[component as keyof typeof componentMap];
};
